import * as TYPES from "./appActionTypes";

const defaultState = {
    authBusy: false,
    isTrucking: true,
    recaptchaSiteKey: "6LeyRj0UAAAAAI3VO-yqJ5OaSSCpKBH6kVSaaK1X"
};

export default function app(state = defaultState, action) {
    switch (action.type) {
        case TYPES.APP_BUSY:
            return {
                ...state,
                appBusy: action.value
            };
        default:
            return state;
    }
}
