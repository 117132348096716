import axios from "axios";
import _get from "lodash/get";
import { URLS } from "../../constants";

const axiosInstance = axios.create({
    baseURL: URLS.BLOG_API
    /* other custom settings */
});

axiosInstance.defaults.timeout = 100000;
axiosInstance.defaults.headers.put["Content-Type"] = "application/json";
axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
axiosInstance.interceptors.request.use(function requestInterceptor(config) {
    // config.headers["x-api-key"] = URLS.CORE_API_KEY;
    config.params = config.params || {};
    config.params.domain = ""
    return config;
});

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const responseCode = _get(error, "response.data.errors[0].code");
        if (responseCode === "UN_AUTHORIZED") {
            console.log(" ******* USER IS UNAUTORIZED ******* ");
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
