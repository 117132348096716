export const preloadedState = {
    users: ["owner", "accountant", "dispatcher"],
    categories: [
        {
            users: ["owner", "dispatcher"],
            slug: "dispatch",
            title: "Dispatch",
            icon: "fa fa-paper-plane"
        },
        {
            users: ["owner", "accountant"],
            slug: "invoice",
            title: "Invoice",
            icon: "fa fa-file"
        },
        {
            users: ["owner", "dispatcher"],
            slug: "dump",
            title: "Dump",
            icon: "fa fa-truck"
        },
        {
            users: ["owner", "accountant"],
            slug: "paycheck",
            title: "Pay Check",
            icon: "fa fa-money-check-alt"
        },
        {
            users: ["owner", "accountant"],
            slug: "driver",
            title: "Driver",
            icon: "fa fa-money-check-alt"
        },
        {
            users: ["owner", "dispatcher"],
            slug: "Company",
            title: "Company",
            icon: "fa fa-truck"
        },
        {
            users: ["owner", "dispatcher"],
            slug: "Carrier",
            title: "Carrier",
            icon: "fa fa-truck"
        },
        {
            users: ["owner", "accountant"],
            slug: "Tractor",
            title: "Tractor",
            icon: "fa fa-money-check-alt"
        },
        {
            users: ["owner", "accountant"],
            slug: "Trailer",
            title: "Trailer",
            icon: "fa fa-money-check-alt"
        },
        {
            users: ["owner", "accountant"],
            slug: "Repair",
            title: "Repair",
            icon: "fa fa-money-check-alt"
        },
        {
            users: ["owner", "accountant"],
            slug: "Expenses",
            title: "Expenses",
            icon: "fa fa-file"
        },
        {
            users: ["owner", "accountant"],
            slug: "Inventory",
            title: "Inventory",
            icon: "fa fa-money-check-alt"
        },
        {
            users: ["owner", "accountant"],
            slug: "Settings",
            title: "Settings",
            icon: "fa fa-money-check-alt"
        },
        {
            users: ["owner", "accountant"],
            slug: "Tripsheet",
            title: "Tripsheet",
            icon: "fa fa-file"
        },
        {
            users: ["owner", "accountant"],
            slug: "Reports",
            title: "Reports",
            icon: "fa fa-file"
        },
        {
            users: ["owner", "accountant"],
            slug: "Accounting",
            title: "Accounting",
            icon: "fa fa-file"
        },
        {
            users: ["owner", "accountant"],
            slug: "Integration",
            title: "Integration",
            icon: "fa fa-file"
        },
        {
            users: ["owner", "accountant"],
            slug: "Documents",
            title: "Documents",
            icon: "fa fa-file"
        },
        {
            users: ["owner", "accountant"],
            slug: "Tracking",
            title: "Tracking",
            icon: "fa fa-file"
        },
        {
            users: ["owner", "accountant"],
            slug: "SMS",
            title: "SMS",
            icon: "fa fa-file"
        },
        {
            users: ["owner", "accountant"],
            slug: "Shippers",
            title: "Shippers",
            icon: "fa fa-file"
        },
        {
            users: ["owner", "accountant"],
            slug: "Estimates",
            title: "Estimates",
            icon: "fa fa-file"
        },
        {
            users: ["owner", "accountant"],
            slug: "Timesheet",
            title: "Timesheet",
            icon: "fa fa-file"
        },
        {
            users: ["owner", "accountant"],
            slug: "Users",
            title: "Users",
            icon: "fa fa-file"
        }
    ],
    topics: {
        dispatch: [
            {
                title: "Create",
                slug: "create",
                details: "Create",
                list: ["i1"],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "Delivery",
                slug: "delivery",
                details: "Create",
                list: ["i1"],
                link: "osrit.com"
            }
        ],
        invoice: [
            {
                title: "Create",
                slug: "create",
                details: "Create",
                list: [],
                link: "osrit.com"
            }
        ],
        driver: [
            {
                title: "How to create a driver?",
                slug: "create-driver",
                details: " ",
                list: [
                    "Click on Driver from left menu.",
                    "Click on 'Add Driver'.",
                    "Fill all the required information.",
                    "Click on the save button."
                ],
                link: "osrit.com"
            },
            {
                title: "How to edit a driver?",
                slug: "edit-driver",
                details:
                    "If anytime driver needs to edit, update or change their information. please follow the steps below:",
                list: [
                    "Open your driver details.",
                    "Click on edit button.",
                    "Edit data.",
                    "Fill all required information.",
                    "click on the Save data."
                ],
                link: "osrit.com"
            },
            {
                title: "How to get driver Details?",
                slug: "driver-details",
                list: [
                    "Open your driver details. ",
                    "Click on the driver from left menu.",
                    "Search driver.",
                    "Click on driver name."
                ]
            },
            {
                title: "How to get driver Documents?",
                slug: "driver-documents",
                list: [
                    "Open your driver details.",
                    "Click on the driver from left menu.",
                    "Search driver.",
                    "Click on driver name.",
                    "click on documents."
                ]
            },
            {
                title: "How to get driver Expense?",
                slug: "driver-expense",
                list: [
                    "Open your driver details.",
                    "Click on the driver from left menu.",
                    "Search driver.",
                    "Click on driver name.",
                    "click on the Expense."
                ]
            },
            {
                title: "How to get driver Credit limit?",
                slug: "driver-credit limit",
                list: [
                    "Open your driver details.",
                    "Click on the driver from left menu.",
                    "Search driver.",
                    "Click on driver name.",
                    "click on the credit limit."
                ]
            },
            {
                title: "How to get driver Advance?",
                slug: "driver-Advance",
                list: [
                    "Open your driver details.",
                    "Click on the driver from left menu.",
                    "Search driver.",
                    "Click on driver name.",
                    "click on the Advance."
                ]
            },
            {
                title: "How to get driver Recurring?",
                slug: "driver-Recurring",
                list: [
                    "Open your driver details.",
                    "Click on the driver from left menu.",
                    "Search driver.",
                    "Click on driver name.",
                    "click on the Recurring."
                ]
            },
            {
                title: "How to get driver's ELD details?",
                slug: "driver-ELD details",
                list: [
                    "Open your driver's ELD details .",
                    "Click on the driver from left menu.",
                    "Search driver.",
                    "Click on driver name.",
                    "click on the ELD."
                ]
            },
            {
                title: "How to get driver Available?",
                slug: "driver-Available",
                list: [
                    "Open your driver details.",
                    "Click on the driver from left menu.",
                    "Search driver.",
                    "Click on driver name.",
                    "click on the Available."
                ]
            },
            {
                title: "How to get driver Contact?",
                slug: "driver-Contact",
                list: [
                    "Open your driver details.",
                    "Click on the driver from left menu.",
                    "Search driver.",
                    "Click on driver name.",
                    "click on the Contact."
                ]
            }
        ],
        Company: [
            {
                title: "How to add Company",
                slug: "add-Company",
                details: "To add Company follow the details below ",
                list: [
                    "Navigate to Company from left menu",
                    "Click on 'Add Company'",
                    "Fill all the required information.",
                    "Click on the save button"
                ],
                link: "osrit.com"
            },
            {
                title: "How to edit Company",
                slug: "edit-Company",
                details:
                    "If anytime driver needs to edit Company's information. update or change their information. please follow the steps below:",
                list: [
                    "Click on Company from left menu",
                    "Open the Comapny number",
                    "Click on 'Edit Comapny'",
                    "Edit the required details",
                    "Click on the save button"
                ],
                link: "osrit.com"
            },
            {
                title: "How to blacklist Company",
                slug: "add-company blacklist",
                details: "Create",
                list: [
                    "Navigate to Company from left men",
                    "Click on blacklisted",
                    "click on add blacklist",
                    "fill all the required information",
                    "click on save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to Edit blacklist Company",
                slug: "add-company blacklist",
                details: "Create",
                list: [
                    "Navigate to Company from left men",
                    "Click on blacklisted",
                    "clickon edit button",
                    "fill all the required information",
                    "click on save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            }
        ],
        Carrier: [
            {
                title: "How to add Carrier",
                slug: "add-Carrier",
                details: "To add Carrier follow the details below ",
                list: [
                    "Navigate to Carrier from left menu",
                    "Click on 'Add Carrier'",
                    "Fill all the required information.",
                    "Click on the save button"
                ],
                link: "osrit.com"
            },
            {
                title: "How to edit Carrier",
                slug: "edit-Carrier",
                details:
                    "If anytime driver needs to edit Carrier information. update or change their information. please follow the steps below:",
                list: [
                    "Click on Carrier from left menu",
                    "Open the Carrier number",
                    "Click on 'Edit Carrier'",
                    "Edit the required details",
                    "Click on the save button"
                ],
                link: "osrit.com"
            },
            {
                title: "How to get Carrier details",
                slug: "add-carrier details",
                details: "Create",
                list: [
                    "Navigate to Company from left men",
                    "Click on the Name of carrier"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to get Carrier document",
                slug: "add-carrier details",
                details: "Create",
                list: [
                    "Navigate to Company from left men",
                    "Click on the Name of carrier",
                    "Select document from top panel"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to get Carrier Insurance",
                slug: "add-carrier details",
                details: "Create",
                list: [
                    "Navigate to Company from left men",
                    "Click on the Name of carrier",
                    "Select Insurance from top panel"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to get Carrier Credit Limit",
                slug: "add-carrier details",
                details: "Create",
                list: [
                    "Navigate to Company from left men",
                    "Click on the Name of carrier",
                    "Select Credit Limit from top panel"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to get Carrier Advances",
                slug: "add-carrier details",
                details: "Create",
                list: [
                    "Navigate to Company from left men",
                    "Click on the Name of carrier",
                    "Select Advance from top panel"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to get Carrier Recuuring",
                slug: "add-carrier details",
                details: "Create",
                list: [
                    "Navigate to Company from left men",
                    "Click on the Name of carrier",
                    "Select Recurring from top panel"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to get Carrier’s ELD",
                slug: "add-carrier details",
                details: "Create",
                list: [
                    "Navigate to Company from left men",
                    "Click on the Name of carrier",
                    "Select ELD from top panel"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to get Carrier’s Tractor",
                slug: "add-carrier details",
                details: "Create",
                list: [
                    "Navigate to Company from left men",
                    "Click on the Name of carrier",
                    "Select Tractor from top panel"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to get Carrier’s Contact",
                slug: "add-carrier details",
                details: "Create",
                list: [
                    "Navigate to Company from left men",
                    "Click on the Name of carrier",
                    "Select Contact from top panel"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            }
        ],
        Tractor: [
            {
                title: "How to add tractor",
                slug: "add-delivery",
                details: "To add tractor follow the details below ",
                list: [
                    "Click On Fleet from left menu.",
                    "Go to tractor from top pannel.",
                    "Click on 'Add Tractor'.",
                    "Fill all the required information.",
                    "Click on the save button."
                ],
                link: "osrit.com"
            },
            {
                title: "How to edit tractor",
                slug: "edit-delivery",
                details:
                    "If anytime driver needs to edit tractor information. update or change their information. please follow the steps below:",
                list: [
                    "Click on fleet from left menu.",
                    "Open the Tractor number.",
                    "Click on 'Edit tractor.'",
                    "Edit the required details.",
                    "click on the save button."
                ],
                link: "osrit.com"
            },
            {
                title: "How to get tractor details",
                slug: "edit-details",
                list: [
                    "Click on fleet from left menu.",
                    "Go to tractor from top pannel.",
                    "Search tractor number",
                    "click on tractor number."
                ],
                link: "osrit.com"
            },
            {
                title: "How to get tractor document",
                slug: "edit-documents",
                list: [
                    "Click on fleet from left menu.",
                    "Go to tractor from top pannel.",
                    "Search tractor number.",
                    "click on tractor number.",
                    "click on document"
                ],
                link: "osrit.com"
            },
            {
                title: "How to get tractor ELD details",
                slug: "edit-ELD Details",
                list: [
                    "Click on fleet from left menu.",
                    "Go to tractor from top pannel.",
                    "Search tractor number.",
                    "click on tractor number.",
                    "click on ELD"
                ],
                link: "osrit.com"
            }
        ],
        Trailer: [
            {
                title: "How to add Trailer?",
                slug: "add-trailer",
                details: "To add triler follow the details below ",
                list: [
                    "Click On Fleet from left menu",
                    "Go to trailer from top pannel",
                    "Click on 'Add Trailer'",
                    "Fill all the required information.",
                    "Click on the save button"
                ],
                link: "osrit.com"
            },
            {
                title: "How to edit Trailer?",
                slug: "edit-trailer",
                details:
                    "If anytime driver needs to edit trailer information. update or change their information. please follow the steps below:",
                list: [
                    "Click on fleet from left menu.",
                    "Go to trailer from top pannel.",
                    "search trailer number.",
                    "click on trailer number.",
                    "Click on 'Edit trailer.'",
                    "Edit data and recheck the required feilds.",
                    "Click on the save button"
                ],
                link: "osrit.com"
            },
            {
                title: "How to get Trailer details?",
                slug: "edit-trailer details",
                list: [
                    "Click on fleet from left menu.",
                    "Go to trailer from top pannel.",
                    "search trailer number.",
                    "click on trailer number."
                ],
                link: "osrit.com"
            }
        ],
        Repair: [
            {
                title: "How to add Repair",
                slug: "add-Repair",
                details: "Create",
                list: [
                    "Click On Fleet from left menu",
                    "Go to Repair from top pannel",
                    "Click on 'repair'",
                    "Fill all the required information.",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit Repair",
                slug: "edit-repair",
                details: "Create",
                list: [
                    "Click on fleet from left menu",
                    "Open the repair number",
                    "Click on 'Edit repair'",
                    "Edit data and recheck the required feilds",
                    "Click on the save button"
                ],
                link: "osrit.com"
            }
        ],
        Expenses: [
            {
                title: "How to add Expenses",
                slug: "add-Expenses",
                details: "Create",
                list: [
                    "Click On Expenses from left menu",
                    "Click on ' Add Expenses'",
                    "Fill all the required information.",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit Expenses",
                slug: "edit-Expenses",
                details: "Create",
                list: [
                    "Click on Expenses from left menu",
                    "Open the Expenses number",
                    "Click on 'Edit Expenses'",
                    "Edit data and recheck the required feilds",
                    "Click on the save button"
                ],
                link: "osrit.com"
            }
        ],
        Inventory: [
            {
                title: "How to add Inventory",
                slug: "add-inventory",
                details: "Create",
                list: [
                    "Click On Fleet from left menu",
                    "Go to inventory from top pannel",
                    "Click on 'Add Inventory'",
                    "Fill all the required information.",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit Inventory",
                slug: "edit-inventory",
                details: "Create",
                list: [
                    "Click on fleet from left menu",
                    "Open the inventory serial number",
                    "Click on 'Edit Inventory'",
                    "Edit data and recheck the required feilds",
                    "Click on the save button"
                ],
                link: "osrit.com"
            }
        ],
        Tripsheet: [
            {
                title: "How to add Tripsheet",
                slug: "add-Tripsheet",
                details: "Create",
                list: [
                    "Click to account(image) from top right and select Tripsheet ",
                    "Click on add trip",
                    "Fill all the required information.  ",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit Tripsheet",
                slug: "edit-Tripsheet",
                details: "Create",
                list: [
                    "Click to account(image) from top right and select Tripsheet ",
                    "Once its open",
                    "Click on 'Edit' in the last cloumn of a row",
                    "Edit data and recheck the required feilds",
                    "Click on the save button"
                ],
                link: "osrit.com"
            }
        ],
        Reports: [
            {
                title: "How to add Reports",
                slug: "add-reports",
                details: "Create",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on 'Add Inventory'",
                    "Fill all the required information.",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit Reports",
                slug: "edit-Reports",
                details: "Create",
                list: [
                    "Click on fleet from left menu",
                    "Open the inventory serial number",
                    "Click on 'Edit Inventory'",
                    "Edit data and recheck the required feilds",
                    "Click on the save button"
                ],
                link: "osrit.com"
            }
        ],
        AccountingDep: [
            {
                title: "How to add Accounting",
                slug: "add-Accounting",
                details: "Create",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on 'Add Inventory'",
                    "Fill all the required information.",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit Accounting",
                slug: "edit-Accoutning",
                details: "Create",
                list: [
                    "Click on fleet from left menu",
                    "Open the inventory serial number",
                    "Click on 'Edit Inventory'",
                    "Edit data and recheck the required feilds",
                    "Click on the save button"
                ],
                link: "osrit.com"
            },
            {
                title: "How to Print Void check",
                slug: "edit-Accoutning",
                details: "Create",
                list: [
                    "Click on fleet from left menu",
                    "Open the inventory serial number",
                    "Click on 'Edit Inventory'",
                    "Edit data and recheck the required feilds",
                    "Click on the save button"
                ],
                link: "osrit.com"
            }
        ],
        Integration: [
            {
                title: "How to add Integration",
                slug: "add-Integration",
                details: "Create",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on 'Add Inventory'",
                    "Fill all the required information.",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit Integration",
                slug: "edit-Integration",
                details: "Create",
                list: [
                    "Click on fleet from left menu",
                    "Open the inventory serial number",
                    "Click on 'Edit Inventory'",
                    "Edit data and recheck the required feilds",
                    "Click on the save button"
                ],
                link: "osrit.com"
            }
        ],
        Documents: [
            {
                title: "How to add Documents",
                slug: "add-Documents",
                details: "Create",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on 'Add Inventory'",
                    "Fill all the required information.",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit Documents",
                slug: "edit-Documents",
                details: "Create",
                list: [
                    "Click on fleet from left menu",
                    "Open the inventory serial number",
                    "Click on 'Edit Inventory'",
                    "Edit data and recheck the required feilds",
                    "Click on the save button"
                ],
                link: "osrit.com"
            }
        ],
        Tracking: [
            {
                title: "How to add Tracking",
                slug: "add-Tracking",
                details: "Create",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on 'Add Inventory'",
                    "Fill all the required information.",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit Tracking",
                slug: "edit-Tracking",
                details: "Create",
                list: [
                    "Click on fleet from left menu",
                    "Open the inventory serial number",
                    "Click on 'Edit Inventory'",
                    "Edit data and recheck the required feilds",
                    "Click on the save button"
                ],
                link: "osrit.com"
            }
        ],
        SMS: [
            {
                title: "How to add SMS",
                slug: "add-SMS",
                details: "Create",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on 'Add Inventory'",
                    "Fill all the required information.",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit SMS",
                slug: "edit-SMS",
                details: "Create",
                list: [
                    "Click on fleet from left menu",
                    "Open the inventory serial number",
                    "Click on 'Edit Inventory'",
                    "Edit data and recheck the required feilds",
                    "Click on the save button"
                ],
                link: "osrit.com"
            }
        ],
        Shippers: [
            {
                title: "How to add Shippers",
                slug: "add-Shippers",
                details: "Create",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on 'Add Inventory'",
                    "Fill all the required information.",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit Shippers",
                slug: "edit-Shippers",
                details: "Create",
                list: [
                    "Click on fleet from left menu",
                    "Open the inventory serial number",
                    "Click on 'Edit Inventory'",
                    "Edit data and recheck the required feilds",
                    "Click on the save button"
                ],
                link: "osrit.com"
            }
        ],
        Estimates: [
            {
                title: "How to add Estimates",
                slug: "add-Estimates",
                details: "Create",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on 'Add Inventory'",
                    "Fill all the required information.",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit Estimates",
                slug: "edit-Estimates",
                details: "Create",
                list: [
                    "Click on fleet from left menu",
                    "Open the inventory serial number",
                    "Click on 'Edit Inventory'",
                    "Edit data and recheck the required feilds",
                    "Click on the save button"
                ],
                link: "osrit.com"
            }
        ],
        TImesheet: [
            {
                title: "How to add Timeheet",
                slug: "add-Timesheet",
                details: "Create",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on 'Add Inventory'",
                    "Fill all the required information.",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit Timesheet",
                slug: "edit-Timesheet",
                details: "Create",
                list: [
                    "Click on fleet from left menu",
                    "Open the inventory serial number",
                    "Click on 'Edit Inventory'",
                    "Edit data and recheck the required feilds",
                    "Click on the save button"
                ],
                link: "osrit.com"
            }
        ],
        Settings: [
            {
                title: "How to edit profile",
                slug: "add-edit profile",
                details: "Create",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on sattings",
                    "click on edit profile",
                    "Fill all the required information.",
                    "click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit Business",
                slug: "add-edit business",
                details: "Create",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on settings",
                    "click on Business",
                    "Click on edit",
                    "Fill all the required information.",
                    "click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to view approvals",
                slug: "add-approvals",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on setting",
                    "Click on the Approval from top pannel"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to add Work lanes",
                slug: "add-worklanes",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on setting",
                    "Click on the Worklanes from top pannel",
                    "Click on add Work lanes",
                    "fill all the required information",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to Edit Work lanes",
                slug: "add-worklanes",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on setting",
                    "Click on the Worklanes from top pannel",
                    "Click on Edit Work lanes",
                    "fill all the required information",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to add Business Alias",
                slug: "add-Business-alias",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on setting",
                    "Click on the Alias from top pannel",
                    "Click on add Alias",
                    "fill all the required information",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to add Business Alias",
                slug: "edit-Business-alias",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on setting",
                    "Click on the Alias from top pannel",
                    "Click on name of Alias",
                    "Click on the Edit",
                    "fill all the required information",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to add notification",
                slug: "add-notification",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on setting",
                    "Click on the Notification from top pannel",
                    "Click on add Notification",
                    "Fill all the required information",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            }
        ],
        Users: [
            {
                title: "How to add Accountant",
                slug: "add-user",
                details: "Create",
                list: [
                    "Go to account(image) from top right from top panel",
                    "Go to settings",
                    "Click on User",
                    "Click on add User",
                    "select accountant from users  role",
                    " Fill all the required information",
                    " click on save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to add Owner",
                slug: "add-user",
                details: "Create",
                list: [
                    "Go to account(image) from top right from top panel",
                    "Go to settings",
                    "Click on User",
                    "Click on add User",
                    "select Owner from user role",
                    " Fill all the required information",
                    " click on save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to add Dispatcher",
                slug: "add-user",
                details: "Create",
                list: [
                    "Go to account(image) from top right from top panel",
                    "Go to settings",
                    "Click on User",
                    "Click on add User",
                    "select Dispatcher from user role",
                    " Fill all the required information",
                    " click on save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit User",
                slug: "add-user",
                details: "Create",
                list: [
                    "Go to account(image) from top right from top panel",
                    "Go to settings",
                    "Click on User",
                    "Select from all Users",
                    "Click on edit button",
                    " Fill all the required information",
                    " click on save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            }
        ],
        Vendor: [
            {
                title: "How to add Vendors",
                slug: "add-vendors",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on setting",
                    "Click on the Vendors from top pannel",
                    "Click on add vendor",
                    "fill all the required information",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit Vendors",
                slug: "edit-vendors",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on setting",
                    "Click on the Vendors from top pannel",
                    "Click on edit vendor",
                    "fill all the required information",
                    "Click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to get Vendors details",
                slug: "add-vendor-details",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on setting",
                    "Click on the Vendors from top pannel",
                    "Click on vendor name"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to get Vendors documents",
                slug: "add-vendor-documents",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on setting",
                    "Click on the Vendors from top pannel",
                    "Click on vendor name",
                    "click on the document from top pannel"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to get Vendors Credit",
                slug: "add-vendor-credit",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on setting",
                    "Click on the Vendors from top pannel",
                    "Click on vendor name",
                    "click on the document from top panel"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to get Vendors Contact",
                slug: "add-vendor-contact",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on setting",
                    "Click on the Vendors from top pannel",
                    "Click on vendor name",
                    "click on the contact from top pannel"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to get Vendors Expense",
                slug: "add-vendor-Expense",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on setting",
                    "Click on the Vendors from top pannel",
                    "Click on vendor name",
                    "click on the Expense from top pannel"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            }
        ],
        Accounting: [
            {
                title: "How to add bank Account",
                slug: "add-bankaccount",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on Accounting",
                    "click on add account",
                    "Fill all the required information",
                    "click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to edit bank Account",
                slug: "edit-bankaccount",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on Accounting",
                    "click on Edit",
                    "Fill all the required information",
                    "click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to Add Income",
                slug: "add-income",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on the Accounting",
                    "Select Income from top panel",
                    "click on add income",
                    "Fill all the required information",
                    "click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to Edit Income",
                slug: "edit-income",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on the accounting",
                    "Click on the Income from top pannel",
                    "click on edit",
                    "Fill all the required information",
                    "click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to view Print check",
                slug: "view-printcheck",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on the accounting",
                    "Click on the Print check  from top pannel"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            }
        ],
        Factoring: [
            {
                title: "How to Add factoring",
                slug: "add-factoring",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on the accounting",
                    "Click on the factoring from top pannel",
                    "click on add factoring",
                    "Fill all the required information",
                    "click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to Edit factoring",
                slug: "edit-factoring",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on the accounting",
                    "Click on the factoring from top pannel",
                    "click on edit factoring",
                    "Fill all the required information",
                    "click on the save button"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to factoring details",
                slug: "add-factoring-details",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on the accounting",
                    "Click on the factoring from top pannel",
                    "click on factoring name"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            },
            {
                title: "How to get factoring contacts",
                slug: "add-factoring-contacts",
                list: [
                    "Go to account(image) from top right from top pannel",
                    "Click on the accounting",
                    "Click on the factoring from top pannel",
                    "click on the factoring name",
                    "click on contacts from top pannel"
                ],
                link: "osrit.com",
                tags: ["osrit"]
            }
        ]
    }
};
