import _get from "lodash/get";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SideBar from "./components/SideBar";
import ScroollBar from "./components/ScrollBar";
import { Helmet } from 'react-helmet';
const ContactUs = React.lazy(() => import("./views/ContactUs"));
const Home = React.lazy(() => import("./views/Home"));
const BlogDetail = React.lazy(() => import("./views/TopicDetail/TopicDetail"));
const Topics = React.lazy(() => import("./views/Topics"));

const loading = () => (
    <div className="page-wrapper">
        <div className="preloader" />
    </div>
);
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarOpen: false,
            xx: "",
            mobileClass: ""
        };
    }
    close = () => {
        this.setState({ xx: "" });
    };

    render() {
        return (
            <Router>
                <React.Suspense fallback={loading()}>
                    <Helmet>
                        <link rel="icon" href="images/fo.ico" type="image/x-icon" />
                        <link rel="shortcut icon" href="images/fo.ico" type="image/x-icon" />
                    </Helmet>
                    <div className={`page-wrapper ${this.state.mobileClass}`}>
                        <Header
                            {...this.props}
                            onMenuClick={() => {
                                this.setState({
                                    mobileClass: "mobile-menu-visible"
                                });
                            }}
                            onMenuClose={() => {
                                this.setState({ mobileClass: "" });
                            }}
                            openSlideBar={() => {
                                this.setState({ xx: "isActive" });
                            }}
                        />

                        <Switch>
                            <Route
                                exact
                                path="/"
                                name="Home"
                                render={props => <Home />}
                            />

                            <Route
                                exact
                                path="/:user"
                                name="Home"
                                render={props => <Home />}
                            />

                            <Route
                                exact
                                path="/category/:category"
                                name="Topics"
                                render={props => <Topics />}
                            />
                            <Route
                                exact
                                path="/:category/:topic"
                                name="Topic Details"
                                render={props => <BlogDetail />}
                            />

                            <Route
                                exact
                                path="/contact-us"
                                name="Contact Us"
                                render={props => <ContactUs />}
                            />
                            <Route
                                render={props => <Home />}
                            />

                        </Switch>
                        <Footer {...this.props} />
                    </div>
                    <SideBar cc={this.close} xx={this.state.xx} />
                    <ScroollBar />
                </React.Suspense>
            </Router>
        );
    }
}

const mapStateToProps = state => {
    return {
        isIt: _get(state, "appData.isIt", false),
        isTrucking: _get(state, "appData.isTrucking", false)
    };
};

export default connect(
    mapStateToProps,
    {}
)(App);
