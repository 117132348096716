export const USERS = "USERS";
export const CATEGORIES = "CATEGORIES";
export const TOPICS = "TOPICS";



export const BLOGS = "BLOGS";
export const BLOG_COUNT = "BLOG_COUNT";
export const BLOG_CATEGORIES = "BLOG_CATEGORIES";
export const BLOG_SELECTED = "BLOG_SELECTED";
export const BLOG_BUSY = "BLOG_BUSY";
export const BLOG_COMMENTS = "BLOG_COMMENTS";
