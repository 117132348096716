import React, { Component } from "react";
import LOGO from "../images/logo.png";
import O_LOGO from "../images/osrit_logo.png";
import { OSRIT_URLS } from "../constants";

class Header extends Component {
    async componentDidMount() { }

    render() {
        const { isTrucking } = this.props;
        return (
            <header className="main-header header-style-three">
                <div className="header-upper">
                    <div className="outer-container">
                        <div className="clearfix">
                            <div className="logo-outer">
                                <div className="logo">
                                    <a href="/">
                                        {isTrucking ? (
                                            <img
                                                alt=""
                                                style={{ height: 60 }}
                                            />
                                        ) : (
                                                <img
                                                    src={LOGO}
                                                    style={{ maxHeight: 60 }}
                                                    alt={
                                                        isTrucking
                                                            ? "Osrit Support"
                                                            : "asritsolutions"
                                                    }
                                                    title={
                                                        isTrucking
                                                            ? "osrit.com"
                                                            : "asritsolutions.com"
                                                    }
                                                />
                                            )}
                                        <span
                                            style={{
                                                color: "white",
                                                fontFamily: "Dosis",
                                                fontSize: 30,
                                                verticalAlign: "middle",
                                                fontWeight: 600
                                            }}
                                        >
                                            {isTrucking
                                                ? "Osrit Support"
                                                : "Asrit Solutions"}
                                        </span>
                                    </a>
                                </div>
                            </div>

                            <div className="nav-outer clearfix">
                                <div
                                    className="mobile-nav-toggler  default-mobile-nav-toggler"
                                    onClick={this.props.onMenuClick}
                                >
                                    <span className="icon flaticon-menu" />
                                </div>
                                <nav className="main-menu navbar-expand-md navbar-light">
                                    <div className="navbar-header">
                                        <button
                                            className="navbar-toggler"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target="#navbarSupportedContent"
                                            aria-controls="navbarSupportedContent"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation"
                                        >
                                            <span className="icon flaticon-menu" />
                                        </button>
                                    </div>
                                    <div
                                        className="collapse navbar-collapse clearfix"
                                        id="navbarSupportedContent"
                                    >
                                        <ul className="navigation clearfix">
                                            <li> <a href={OSRIT_URLS.HOME}>Home</a> </li>
                                            <li> <a href={OSRIT_URLS.CONTACT_US}> {"Contact Us"} </a> </li>
                                        </ul>
                                    </div>
                                </nav>

                                {!isTrucking && (
                                    <div className="outer-box clearfix">
                                        <div className="nav-box">
                                            <div
                                                onClick={
                                                    this.props.openSlideBar
                                                }
                                                className="nav-btn nav-toggler navSidebar-button clearfix"
                                            >
                                                <span className="icon" />
                                                <span className="icon" />
                                                <span className="icon" />
                                            </div>
                                        </div>

                                        {/* <div className="search-box-btn"><span className="icon flaticon-magnifying-glass-1"></span></div> */}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Mobile menu */}
                <div className="mobile-menu">
                    <div
                        className="menu-backdrop"
                        onClick={this.props.onMenuClose}
                    />
                    <div className="close-btn" onClick={this.props.onMenuClose}>
                        <span className="icon flaticon-cancel" />
                    </div>
                    <nav className="menu-box mCustomScrollbar _mCS_1">
                        <div
                            id="mCSB_1"
                            className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                            tabIndex="0"
                            style={{ maxHeight: 440 }}
                        >
                            <div
                                id="mCSB_1_container"
                                className="mCSB_container"
                                style={{
                                    position: "relative",
                                    top: 0,
                                    left: 0
                                }}
                                dir="ltr"
                            >
                                <div className="nav-logo">
                                    <a href="index.html">
                                        <img
                                            src={isTrucking ? O_LOGO : LOGO}
                                            style={{ maxHeight: 60 }}
                                            alt={
                                                isTrucking
                                                    ? "osrit"
                                                    : "asritsolutions"
                                            }
                                            title={
                                                isTrucking
                                                    ? "osrit.com"
                                                    : "asritsolutions.com"
                                            }
                                            className="mCS_img_loaded"
                                        />
                                        <span
                                            style={{
                                                color: "white",
                                                fontFamily: "Dosis",
                                                fontSize: 20,
                                                verticalAlign: "middle",
                                                fontWeight: 600
                                            }}
                                        >
                                            {isTrucking
                                                ? "Osrit"
                                                : "Asrit Solutions"}
                                        </span>
                                    </a>
                                </div>
                                <div className="menu-outer">
                                    <ul className="navigation clearfix">
                                        <li> <a href={OSRIT_URLS.HOME}>Home</a> </li>
                                        <li> <a href={OSRIT_URLS.CONTACT_US}> {"Contact Us"}                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="social-links">
                                    <ul className="clearfix">
                                        <li>
                                            <a href={OSRIT_URLS.FACEBOOK}>
                                                <span className="fab fa-facebook-square" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={OSRIT_URLS.TWITTER}>
                                                <span className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={OSRIT_URLS.PINTEREST}>
                                                <span className="fab fa-pinterest-p" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={OSRIT_URLS.INSTA}>
                                                <span className="fab fa-instagram" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                id="mCSB_1_scrollbar_vertical"
                                className="mCSB_scrollTools mCSB_1_scrollbar mCS-light mCSB_scrollTools_vertical"
                                style={{ display: "block" }}
                            >
                                <div className="mCSB_draggerContainer">
                                    <div
                                        id="mCSB_1_dragger_vertical"
                                        className="mCSB_dragger"
                                        style={{
                                            position: "absolute",
                                            minHeight: 30,
                                            height: 365,
                                            top: 0,
                                            display: "block",
                                            maxHeight: 414
                                        }}
                                        onContextMenu={() => false}
                                    >
                                        <div
                                            className="mCSB_dragger_bar"
                                            style={{ lineHeight: 30 }}
                                        />
                                    </div>
                                    <div className="mCSB_draggerRail" />
                                </div>
                            </div>
                        </div>
                    </nav>{" "}
                </div>
            </header>
        );
    }
}
export default Header;
