import * as TYPES from "./supportActionTypes";
import { preloadedState } from "../../preloadedState";

const defaultState = {
    count: 0,
    loading: false,
    users: preloadedState.users,
    categories: preloadedState.categories,
    topics: preloadedState.topics
};

export default function support(state = defaultState, action) {
    switch (action.type) {
        case TYPES.USERS:
            return {
                ...state,
                users: action.value
            };
        case TYPES.CATEGORIES:
            return {
                ...state,
                categories: action.value
            };
        case TYPES.TOPICS:
            return {
                ...state,
                topics: action.value
            };


        case TYPES.BLOG_BUSY:
            return {
                ...state,
                loading: action.value
            };
        case TYPES.BLOG_COUNT:
            return {
                ...state,
                count: action.value
            };
        case TYPES.BLOG_CATEGORIES:
            return {
                ...state,
                categories: action.value
            };
        case TYPES.BLOGS:
            return {
                ...state,
                blogs: action.value
            };
        case TYPES.BLOG_SELECTED:
            return {
                ...state,
                blogSelected: action.value
            };
        case TYPES.BLOG_COMMENTS:
            return {
                ...state,
                blogComments: action.value
            };

        default:
            return state;
    }
}
