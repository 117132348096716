export const URLS = {
    BLOG_API: process.env.REACT_APP_BLOG_API,
    IT: {
        CONTACT_US: "https://www.asritsolutions.com/contactUs"
    },
    TRUCKING: {
        CONTACT_US: "https://www.osrit.com/#"
    }
};

export function getDomain() {
    const url = window.location.hostname;
    const hostname = url.split(".").slice(-2);
    return hostname.length === 1 ? "localhost" : `.${hostname.join(".")}`;
}

export const OSRIT_URLS = {
    HOME: "//www.osrit.com/",
    ASRIT_HOME: "//www.asritsolutions.com/",
    CONTACT_US: "//www.osrit.com/contact-us",
    DISPATCH: "//www.osrit.com/dispatch",
    PRICE: "//www.osrit.com/pricing",

    TERMS: "//www.osrit.com/terms",
    POLICY: "//www.osrit.com/policy",

    TWITTER: "//twitter.com/OsritSoftware",
    FACEBOOK: "//www.facebook.com/osritTrucking",
    PINTEREST: "//www.pinterest.com/OsritTruckingSoftware",
    REDDIT: "//www.reddit.com/user/osrit_truck_software",
    INSTA: "//www.instagram.com/osrit_trucking_software",
    LINKED_IN: "//www.linkedin.com/"
};
