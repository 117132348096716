/* eslint-disable */
import React, { Component } from "react";
import { OSRIT_URLS } from "../constants";
import LOGO from "../images/logo.png";

class SideBar extends Component {
	async componentDidMount() {
	}

	render() {
		var classX = "xs-sidebar-group info-group info-sidebar " + this.props.xx
		return (
			<div className={classX}>
				<div className="xs-overlay xs-bg-black"
					onClick={this.props.cc}
				></div>
				<div className="xs-sidebar-widget">
					<div className="sidebar-widget-container"
						onClick={this.props.cc}
					>
						<div className="widget-heading">
							<span className="close-side-widget" style={{
								cursor: "pointer"
							}}>
								X
				</span>
						</div>
						<div className="sidebar-textwidget">
							<div className="sidebar-info-contents">
								<div className="content-inner">
									<div className="logo">
										<a href={OSRIT_URLS.HOME}><img src={LOGO} alt="asritsolution" /></a>
									</div>
									<div className="content-box">
										<h2>About Us</h2>
										<p className="text"><b><span style={{ fontSize: '30px' }}>A</span>srit Solutions</b> provides a wide range of information technology solutions and services to boost you business. Highly skilled and experienced management of Asrit Solutions constitutes a strong team of qualified, experienced and dedicated professionals for various IT Initiatives.</p>
										<a href={OSRIT_URLS.ABOUT} className="theme-btn btn-style-one"><span className="txt">About Us</span></a>
									</div>
									<div className="contact-info">
										<h2>Contact Info</h2>
										<ul className="list-style-one">
											<li><span className="icon flaticon-map"></span>46560 Fremont Blvd Suit 302 Fremont, CA- 94538</li>
											<li><span className="icon flaticon-telephone"></span><a href="tel:+1 (408) 520-0014">+1 (408) 520-0014</a></li>
											<li><span className="icon flaticon-message-1"></span><a href="mailto:contactus@asritsolutions.com">contactus@asritsolutions.com</a></li>
											<li><span className="icon flaticon-timetable"></span>Week Days: 09.00 to 18.00 Sunday: Closed</li>
										</ul>
									</div>
									<ul className="social-box">
										<li className="facebook"><a href={OSRIT_URLS.FACEBOOK} className="fab fa-facebook-f"></a></li>
										<li className="twitter"><a href={OSRIT_URLS.TWITTER} className="fab fa-twitter"></a></li>
										<li className="linkedin"><a href={OSRIT_URLS.LINKED_IN} className="fab fa-linkedin-in"></a></li>
										<li className="instagram"><a href={OSRIT_URLS.INSTA} className="fab fa-instagram"></a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default SideBar;
