import _get from "lodash/get";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onSubscribe } from "../actions/support/supportActions";
import RecaptchaVerify from "./RecaptchaVerify";
import { OSRIT_URLS } from "../constants";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: ""
        };
    }

    onSubmit = async e => {
        e.preventDefault();
        this.setState({ isCaptchaOpen: true });
    };

    _onSubmit = async e => {
        const { email, captchaVal } = this.state;
        this.setState({
            loading: true,
            error: "",
            success: "",
            isCaptchaOpen: false
        });
        const response = await this.props.onSubscribe({
            email,
            captchaVal,
            domain: "",
            subscription: ""
        });
        if (response && response.id) {
            this.setState({
                loading: false,
                success: `Email is subscribed sucessfully.`
            });
        } else if (response && response.errorCode === "ER_DUP_ENTRY") {
            this.setState({
                loading: false,
                error: `Email is already subscribed.`
            });
        } else {
            this.setState({
                loading: false,
                error: `Something went wrong, please try after sometime.`
            });
        }
    };

    render() {
        //   const { isTrucking } = this.props;
        const { isCaptchaOpen, loading, error, success } = this.state;
        return (
            <div>
                {isCaptchaOpen && (
                    <RecaptchaVerify
                        isOpen={isCaptchaOpen}
                        handleClose={() =>
                            this.setState({ isCaptchaOpen: false })
                        }
                        onDone={captchaVal => {
                            this.setState({ captchaVal }, () => {
                                this._onSubmit();
                            });
                        }}
                    />
                )}
                <footer className="main-footer">
                    <div className="auto-container">
                        <div className="widgets-section">
                            <div className="row clearfix">
                                <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                                    <div className="footer-widget logo-widget">
                                        <div className="logo">
                                            <a href={OSRIT_URLS.HOME}>
                                                <span
                                                    style={{
                                                        color: "#222222",
                                                        fontFamily: "Dosis",
                                                        fontSize: 28,
                                                        verticalAlign: "middle",
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    Osrit
                                                </span>
                                            </a>
                                        </div>
                                        <div
                                            className="text"
                                            style={{ textAlign: "justify" }}
                                        >
                                            Osrit is a web based cloud trucking software. It helps you manage customers, vendors, dispatch, drivers, trucks, trailors, expenses etc.
                                        </div>
                                        {/* <SocialIcons /> */}
                                        <ul className="social-icons">
                                            <li className="facebook">
                                                <a href={OSRIT_URLS.FACEBOOK}>
                                                    <span className="fab fa-facebook-f ic" />
                                                </a>
                                            </li>
                                            <li className="twitter">
                                                <a href={OSRIT_URLS.TWITTER}>
                                                    <span className="fab fa-twitter ic" />
                                                </a>
                                            </li>
                                            <li className="linkedin">
                                                <a href={OSRIT_URLS.LINKED_IN}>
                                                    <span className="fab fa-linkedin-in ic" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                                    <div className="footer-widget links-widget">
                                        <h2>Quick Link</h2>
                                        <div className="widget-content">
                                            <ul className="list">
                                                <li>
                                                    <a href={OSRIT_URLS.HOME}>Home</a>
                                                </li>
                                                <li>
                                                    <a href={OSRIT_URLS.DISPATCH}> Dispatch </a>
                                                </li>
                                                <li>
                                                    <a href={OSRIT_URLS.PRICE}> Price </a>
                                                </li>
                                                <li>
                                                    <a href={OSRIT_URLS.CONTACT_US}> Contact Us </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="footer-column col-lg-5 col-md-12 col-sm-12">
                                    <div className="footer-widget newsletter-widget">
                                        <h2>Newsletter</h2>
                                        <div className="text">
                                            Subscribe to our newsletter and stay
                                            updates for all technology, offer,
                                            jobs alert!
                                        </div>
                                        <div className="newsletter-form">
                                            <form onSubmit={this.onSubmit}>
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        placeholder="Your email"
                                                        required
                                                        disabled={loading}
                                                        onChange={e =>
                                                            this.setState({
                                                                email:
                                                                    e.target
                                                                        .value
                                                            })
                                                        }
                                                    />
                                                    <button
                                                        type="submit"
                                                        className="theme-btn btn-style-four"
                                                    >
                                                        <span className="txt">
                                                            {loading
                                                                ? "Please wait"
                                                                : "Subscribe"}
                                                        </span>
                                                    </button>
                                                    <h3
                                                        style={{
                                                            marginTop: 12,
                                                            textAlign: "center",
                                                            color: error
                                                                ? "red"
                                                                : "green"
                                                        }}
                                                    >
                                                        {success}
                                                        {error}
                                                    </h3>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-bottom style-four">
                        <div className="auto-container">
                            <div className="clearfix">
                                <div className="pull-left">
                                    <div className="copyright">
                                        <a href={OSRIT_URLS.ASRIT_HOME} style={{ color: "white" }}>Asritsolutions</a> &copy; 2020 All Right
                                        Reserved
                                    </div>
                                </div>
                                <div className="pull-right">
                                    <ul className="footer-list">
                                        <li>
                                            <a href={OSRIT_URLS.TERMS}>Terms of Service</a>
                                        </li>
                                        <li>
                                            <a href={OSRIT_URLS.POLICY}>Privacy Policy</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        blogs: _get(state, "supportData.blogs", []),
        count: _get(state, "supportData.count", 0),
        categories: _get(state, "supportData.categories", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ onSubscribe }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer);
